import { RefObject, useRef, useEffect, useCallback, useState, forwardRef } from 'react'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faCircleXmark } from '@fortawesome/pro-duotone-svg-icons'
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons'

import TextInput, { ITextInputProps } from 'src/components/01-atoms/TextInput'
import Button from 'src/components/01-atoms/Button'

export interface ISimpleSearchProps extends Omit<ITextInputProps, 'type'> {
  disabled?: boolean
  handleSubmit?: CallableFunction
  handleClear?: CallableFunction
  handleChange?: CallableFunction
  handleFocus?: CallableFunction
  handleBlur?: CallableFunction
  handleDropdownClick?: CallableFunction
  showSearchButton?: boolean
  showDropdownButton?: boolean
  showSpyGlass?: boolean
  forceUpdateValue?: string
  testId?: string
  className?: string

  /**
   * Force the clear button to show or not.
   */
  useClearButton?: boolean

  /**
   * Whether or not this simple search is its own form.
   */
  asStandaloneForm?: boolean
}

const SimpleSearch = forwardRef<HTMLInputElement, ISimpleSearchProps>(
  (
    {
      disabled,
      handleSubmit = () => {},
      handleClear = () => {},
      handleChange = () => {},
      handleFocus = () => {},
      handleBlur = () => {},
      handleDropdownClick = () => {},
      defaultValue = '',
      forceUpdateValue = '',
      placeholder = 'Search orders',
      showDropdownButton = false,
      showSearchButton = true,
      showSpyGlass = false,
      testId = 'table-search-form',
      className,
      useClearButton = true,
      asStandaloneForm = false,
      ...textInputProps
    },
    ref
  ) => {
    const [ showClearButton, setShowClearButton ] = useState( !!defaultValue && useClearButton )
    const inputRef = useRef() as RefObject<HTMLInputElement>

    const clearSearch = useCallback(() => {
      if ( !inputRef.current || inputRef.current.value === defaultValue ) return
      inputRef.current.value = ''
      setShowClearButton( false )
    }, [ inputRef, defaultValue ])

    useEffect(() => {
      clearSearch()
    }, [ defaultValue, clearSearch ])

    useEffect(() => {
      if ( inputRef.current ) {
        inputRef.current.value = forceUpdateValue.trim()
      }
    }, [ forceUpdateValue ])

    const Element = asStandaloneForm ? 'form' : 'fieldset'
    const showSideButtons = showDropdownButton || showSearchButton

    return (
      <Element
        onSubmit={( e ) => {
          e.preventDefault()
          handleSubmit( inputRef?.current?.value )
        }}
        data-testid={testId}
        className={`relative flex w-full text-gb-gray-900 ${className}`}
      >
        {showSpyGlass && (
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className="absolute z-10 pt-2.5 pl-2.5 text-lg text-gb-gray-800"
          />
        )}
        <div className="relative w-full">
          <TextInput
            type="text"
            className={classNames(
              'border-2 border-gb-gray-400 bg-white placeholder:text-gb-gray-800 disabled:placeholder-shown:opacity-50',
              {
                'pl-8': showSpyGlass,
                'border-r-0 rounded-r-none': showSideButtons,
              }
            )}
            onChange={( e ) => {
              setShowClearButton( useClearButton && !isEmpty( e.currentTarget.value ))
              handleChange( e.currentTarget.value )
            }}
            onBlur={() => handleBlur()}
            onFocus={() => handleFocus()}
            ref={ref || inputRef}
            defaultValue={disabled ? '' : defaultValue}
            disabled={disabled}
            placeholder={placeholder}
            {...textInputProps}
          />
          <div className="absolute inset-y-0 right-0">
            {showClearButton && (
              <button
                type="button"
                name="clear-search-query"
                className="disabled:opacity-50 h-10 w-8"
                disabled={disabled}
                onClick={() => {
                  clearSearch()
                  handleClear()
                }}
              >
                <FontAwesomeIcon icon={faCircleXmark} />
                <div className="sr-only">Clear search text</div>
              </button>
            )}
          </div>
        </div>
        {showSideButtons && (
          <div className="flex h-10">
            {showDropdownButton && (
              <Button
                data-testid="search-dropdown-button"
                className={classNames( 'center border-2 px-0 rounded-l-none border-l-1', {
                  'rounded-none border-r-0': showSearchButton,
                })}
                variant="secondary"
                outline
                onClick={() => handleDropdownClick()}
                disabled={disabled}
              >
                <span className="sr-only">Open Search Dropdown</span>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="text-md px-4 py-3 text-gb-gray-800"
                />
              </Button>
            )}
            {showSearchButton && (
              <Button
                type={asStandaloneForm ? 'submit' : 'button'}
                data-testid="search-button"
                onClick={
                  !asStandaloneForm ? () => handleSubmit( inputRef?.current?.value ) : undefined
                }
                disabled={disabled}
                className="flex rounded-l-none rounded-r disabled:opacity-50"
              >
                <span className="sr-only">Search Orders</span>
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </Button>
            )}
          </div>
        )}
      </Element>
    )
  }
)

export default SimpleSearch
