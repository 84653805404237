import classNames from 'classnames'
import kebabCase from 'lodash/kebabCase'
import { FC, MouseEvent } from 'react'
import Button, { ButtonProps } from 'src/components/01-atoms/Button'
import PopoverContent, { IPopoverContentProps } from '../PopoverContent'
import { IChildLink } from 'src/utils/types/IChildLink'

export type TNavDropdownLink = ( ButtonProps & { text: string }) | IChildLink
export interface INavDropdownContent extends IPopoverContentProps {
  /**
   * An array of links to show in the dropdown.
   */
  links: TNavDropdownLink[]

  /**
   * Action to take when a link is clicked.
   */
  handleLinkClick?: CallableFunction
}

const NavDropdownContent: FC<INavDropdownContent> = ({
  links,
  handleLinkClick = () => {},
  className = '',
  ...popoverProps
}) => {
  const elementClasses = 'block py-1 px-3 w-full text-gb-gray-900 text-left'

  return (
    <PopoverContent align="center" className={classNames( className, 'z-50' )} {...popoverProps}>
      <nav data-testid="nav-dropdown-content">
        {links.map(({ onClick = () => {}, ...link }) => (
          <Button
            key={`navlink-${kebabCase( link.text )}`}
            data-testid={`navlink-${kebabCase( link.text )}`}
            className={classNames(
              elementClasses,
              'hover:bg-gb-blue-600/25 rounded hover:no-underline disabled:opacity-50 disabled:text-gb-dark-gray disabled:hover:bg-inherit'
            )}
            variant="clear"
            onClick={( event: MouseEvent<HTMLButtonElement & HTMLAnchorElement> ) => {
              onClick( event )
              handleLinkClick()
            }}
            {...( link as ButtonProps )}
          >
            {link.text}
          </Button>
        ))}
      </nav>
    </PopoverContent>
  )
}

export default NavDropdownContent
