import throttle from 'lodash/throttle'
import { FC, useMemo, useState } from 'react'
import AccountPackageSearch from 'src/components/02-molecules/AccountPackageSearch'
import useAppParams from 'src/utils/hooks/useAppParams'
import useSearchAccounts from 'src/utils/hooks/useSearchAccounts'
import useSearchPackages from 'src/utils/hooks/useSearchPackages'

const GlobalSearch: FC = () => {
  const { isAdmin, mdashAccountId, mdashAccountPermissions } = useAppParams()
  const { canQueryPurchaserInfo } = mdashAccountPermissions || {}
  const [ query, setQuery ] = useState( '' )
  const [ isGlobalSearch, setIsGlobalSearch ] = useState( false )
  const { data: packageSearchResults, fetching: isSearchingPackages } = useSearchPackages({
    query,
    isGlobalSearch,
  })

  const { data: accountSearchResults } = useSearchAccounts({ query })

  const throttleQuery = useMemo(() => throttle(( v ) => setQuery( v ), 500 ), [])

  return (
    <div className="hidden md:flex absolute left-0 w-full items-center justify-center pointer-events-none lg:pl-16">
      <AccountPackageSearch
        packageSearchResults={packageSearchResults?.searchPackages?.map(( x ) => ({
          packageId: x.id,
          orderId: x.orderId,
          purchaserName: x.purchaserName,
          recipientName: String( x.deliveryFullName ),
          companyName: x.deliveryCompany,
          shipDate: x.toShipOn,
          packageDetailLink: `${x.mdashAccountId}/package/${x.id}`,
          manifestLink: `${x.mdashAccountId}/manifest/${x.toShipOn}`,
          isOnDifferentAccount: mdashAccountId !== x.mdashAccountId,
          isCancelled: x.gbmcState === 'canceled',
        }))}
        accountSearchResults={accountSearchResults?.searchAccounts?.map(( x ) => ({
          id: x.id,
          name: x.name,
        }))}
        isSearchingPackages={isSearchingPackages}
        allowsGlobalSearch={isAdmin}
        canQueryPurchaserInfo={!!canQueryPurchaserInfo}
        onChange={( x ) => throttleQuery( x )}
        onScopeChange={( x ) => setIsGlobalSearch( x )}
      />
    </div>
  )
}

export default GlobalSearch
