query getUserIdentity {
  identity {
    adminUser {
      id
      name
      email
    }
    merchantUser {
      id
      mdashAccount {
        id
        name
        smsNotificationEnabled
        displaySku
        isMultiPickUpLocation
        isMultiShippingFacility
        isPickUpEnabled
      }
      name
      email
      permissions {
        canCancelSuborders
        canDownloadTracking
        canEditDeliveryDate
        canEditGiftMessages
        canManageSubproductLimits
        canUploadTracking
        canUseGuac
        canViewIssues
        canViewPerformance
        canViewProducts
        canViewPurchaser
        canViewStatements
      }
    }
  }
}
