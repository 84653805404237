import { useContext } from 'react'

import { IMDashAccountPermissionSet, IMerchantUserPermissionSet } from 'src/graphql/types'
import UserContext from 'src/contexts/UserContext'

interface IPermissionProps {
  /**
   * The keys to look for in the user's permissions object.
   */
  userPermissions?: Array<keyof IMerchantUserPermissionSet>

  /**
   * The keys to look for in the account's permissions object.
   */
  accountPermissions?: Array<keyof IMDashAccountPermissionSet>

  /**
   * Whether to ignore the admin check for user permissions.
   */
  excludeAdminCheck?: boolean
}

const useCheckPermission = ({
  userPermissions = [],
  accountPermissions = [],
  excludeAdminCheck = false,
}: IPermissionProps ): boolean => {
  const { identity } = useContext( UserContext )

  if ( !identity || !identity.userId ) return false

  /**
   * These are specifically permissions for the account, so being an admin here will not
   * automatically give permission.
   * See Gloria's explanation here (her responses to the linked comment):
   * https://github.com/Goldbely/mdashx-ui/pull/1378#discussion_r1164410964
   */
  const accountHasPermission =
    accountPermissions.length > 0 &&
    accountPermissions.some(( permission ) => identity?.mdashAccountPermissions?.[permission])

  /**
   * Admins have all user permissions.
   */
  const userHasPermission =
    ( !excludeAdminCheck && identity.isAdmin ) ||
    ( userPermissions.length > 0 &&
      userPermissions.some(( permission ) => identity?.merchantUserPermissions?.[permission]))

  return userHasPermission || accountHasPermission
}

export default useCheckPermission
