import { FC, PropsWithChildren, ReactElement, useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import UserContext from 'src/contexts/UserContext'
import useAppParams from 'src/utils/hooks/useAppParams'

import { IMDashAccountPermissionSet, IMerchantUserPermissionSet } from 'src/graphql/types'
import useCheckPermission from 'src/utils/hooks/useCheckPermission'

interface IProtectedProps {
  /**
   * The keys to look for in the user's permissions object.
   */
  userPermissions?: Array<keyof IMerchantUserPermissionSet>

  /**
   * The keys to look for in the account's permissions object.
   */
  accountPermissions?: Array<keyof IMDashAccountPermissionSet>
}

/**
 * Protect certain markup or a whole page by the user or MDash Account permissions.
 */
const ProtectedByPermission: FC<PropsWithChildren<IProtectedProps>> = ({
  userPermissions = [],
  accountPermissions = [],
  children,
}) => {
  const { makeLinkUrls } = useAppParams()
  const { identity } = useContext( UserContext )
  const hasPermission = useCheckPermission({ userPermissions, accountPermissions })

  if ( !identity || !identity.userId ) return null

  if ( !children ) {
    if ( !hasPermission ) {
      return <Navigate to={makeLinkUrls().manifestBase} replace />
    }
    return <Outlet />
  }

  if ( !hasPermission ) {
    return null
  }
  return children as ReactElement
}

export default ProtectedByPermission
