import { FC, PropsWithChildren } from 'react'
import Markdown from 'markdown-to-jsx'

import Alert, { IAlertProps } from 'src/components/01-atoms/Alert'

import './styles.css'

export type IMegaphoneType = 'info' | 'warning' | 'alert'
export interface IMegaphoneProps extends Omit<IAlertProps, 'size' | 'type'> {
  /**
   * The type of megaphone to show. This also affects border, text, and icon colors.
   */
  type?: IMegaphoneType

  /**
   * String to be rendered as markdown
   */
  markdown?: string
}
const Megaphone: FC<PropsWithChildren<IMegaphoneProps>> = ({
  type = 'info',
  className = '',
  markdown,
  ...props
}) => (
  <Alert
    {...props}
    className={`${className} block rounded-none megaphone justify-center items-center`}
    type={type}
    innerClassname="container px-6 justify-center items-center"
  >
    <div data-testid="megaphone-text">{markdown && <Markdown>{markdown}</Markdown>}</div>
  </Alert>
)

export default Megaphone
