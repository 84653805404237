import { FC, PropsWithChildren } from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmarkLarge } from '@fortawesome/pro-solid-svg-icons'
import {
  DialogOverlay,
  DialogTrigger,
  Dialog,
  DialogContent,
  DialogProps,
  DialogClose,
} from '@radix-ui/react-dialog'

import Button from 'src/components/01-atoms/Button'

import mergeClassNames from 'src/utils/helpers/mergeClassNames'

export interface IModalProps extends DialogProps {
  title?: string | null
  takeoverOnMobile?: boolean
  showTrigger?: boolean
  showCloseButton?: boolean
  handleClose?: CallableFunction
  closeOnBlur?: boolean
  contentClassName?: string
  overlayClassname?: string
}

const Modal: FC<PropsWithChildren<IModalProps>> = ({
  title = '',
  takeoverOnMobile = false,
  showTrigger = false,
  showCloseButton = false,
  handleClose = () => {},
  closeOnBlur = false,
  contentClassName = '',
  overlayClassname = '',
  children,
  ...props
}) => (
  <Dialog modal {...props}>
    {showTrigger && (
      <DialogTrigger asChild>
        <Button>Open Modal</Button>
      </DialogTrigger>
    )}
    <DialogOverlay
      className={mergeClassNames(
        'bg-black/25 fixed z-90 inset-0 backdrop-blur-sm print:hidden',
        overlayClassname
      )}
    />
    <DialogContent
      onInteractOutside={( e ) => {
        if ( !closeOnBlur ) e.preventDefault()
      }}
      className={mergeClassNames(
        'inline-block fixed md:max-w-screen-md lg:max-w-screen-lg overflow-y-auto rounded shadow-lg p-4 text-sm bg-white z-100 print:hidden',
        {
          'inset-0 max-h-screen md:max-h-[90vh] md:w-full md:left-1/2 md:bottom-auto md:right-auto md:top-[50vh] md:-translate-x-1/2 md:-translate-y-1/2':
            takeoverOnMobile,
          'top-[50vh] max-h-[90vh] max-w-[90vw] w-full left-1/2 -translate-x-1/2 -translate-y-1/2':
            !takeoverOnMobile,
        },
        contentClassName
      )}
    >
      {( !!title || showCloseButton ) && (
        <div
          className={classNames( 'flex mb-4 gap-x-3', {
            'justify-start md:justify-between': title && takeoverOnMobile,
            'justify-between': title && !takeoverOnMobile,
            'justify-end': !takeoverOnMobile,
            'md:justify-end': !title,
          })}
        >
          {showCloseButton && (
            <DialogClose
              asChild
              className={classNames({ 'order-2': !takeoverOnMobile }, 'md:order-2' )}
              data-testid="modal-close"
            >
              <FontAwesomeIcon
                onClick={() => handleClose()}
                icon={faXmarkLarge}
                className="cursor-pointer text-gb-gray-800 w-4 h-4"
              />
            </DialogClose>
          )}
          {title && <div className="text-xs font-medium">{title}</div>}
        </div>
      )}
      {children}
    </DialogContent>
  </Dialog>
)

export default Modal
