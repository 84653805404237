import { StrictMode, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider as UrqlProvider } from 'urql'
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react'
import { Analytics } from '@vercel/analytics/react'
import { SpeedInsights } from '@vercel/speed-insights/react';

import client from 'src/graphql/client'
import rollbarConfig from 'src/rollbar.config'

import App from './App'
import BrowserWarningMegaphone from './pages/elements/BrowserWarningMegaphone'
import AppLoading from './components/05-pages/AppLoading'

createRoot( document.getElementById( 'root' )! ).render(
  <RollbarProvider config={rollbarConfig}>
    <BrowserWarningMegaphone />
    <SpeedInsights sampleRate={Number( import.meta.env.SPEED_INSIGHT_SAMPLE_RATE ) || 0.1} />
    <ErrorBoundary>
      <StrictMode>
        <BrowserRouter>
          <UrqlProvider value={client}>
            <Suspense fallback={<AppLoading />}>
              <App />
            </Suspense>
          </UrqlProvider>
        </BrowserRouter>
        <Analytics />
      </StrictMode>
    </ErrorBoundary>
  </RollbarProvider>
)
