import { FC } from 'react'
import { Outlet } from 'react-router-dom'
import { faBoxHeart, faEnvelope, faQuestionCircle } from '@fortawesome/pro-duotone-svg-icons'

import DefaultSkeletonTheme from 'src/components/01-atoms/DefaultSkeletonTheme'
import GlobalHeader from 'src/components/03-organisms/GlobalHeader'
import GlobalFooter from 'src/components/03-organisms/GlobalFooter'
import IconLink from 'src/components/01-atoms/IconLink'
import { IUserDropdownProps } from 'src/components/02-molecules/UserDropdown'
import MainNav from 'src/components/03-organisms/MainNav'
import { IMainNavProps } from 'src/components/03-organisms/MainNav/interface'
import ZendeskBadge from 'src/components/01-atoms/ZendeskBadge'
import Megaphone, { IMegaphoneProps } from 'src/components/01-atoms/Megaphone'
import MegaphoneModal, { IMegaphoneModalProps } from 'src/components/03-organisms/MegaphoneModal'

interface IDefaultLayoutProps extends IUserDropdownProps, IMainNavProps {
  /**
   * Megaphone props.
   */
  megaphone?: IMegaphoneProps | null | undefined

  /**
   * Megaphone modal props.
   */
  modal?: IMegaphoneModalProps | null | undefined
}

// TODO - Clean this up!!!
const DefaultLayout: FC<IDefaultLayoutProps> = ({ megaphone, modal, ...props }) => {
  const variant = props.isAdmin ? 'darker' : 'light'
  const { markdown, type } = megaphone || {}

  return (
    <div className="flex flex-col min-h-[100vh]">
      <div className="print:hidden">
        {!!markdown && !!type && <Megaphone {...megaphone} />}
        {!!modal && <MegaphoneModal {...modal} />}
        <GlobalHeader {...props} />
        <MainNav {...props} />
      </div>
      <DefaultSkeletonTheme>
        <main className="flex-grow flex flex-col">
          <Outlet />
        </main>
      </DefaultSkeletonTheme>
      <div className="print:hidden">
        <GlobalFooter isAdmin={props.isAdmin}>
          <IconLink
            variant={variant}
            href="//swag.goldbelly.com/"
            target="_blank"
            icon={faBoxHeart}
          >
            Order Swag
          </IconLink>
          <IconLink
            variant={variant}
            href="//www.goldbelly.com/merchant_support/sso"
            target="_blank"
            icon={faQuestionCircle}
          >
            Help &amp; Support
          </IconLink>
          <IconLink
            variant={variant}
            href="mailto:partners@goldbelly.com"
            target="_blank"
            icon={faEnvelope}
          >
            Email partners@goldbelly.com
          </IconLink>
        </GlobalFooter>
      </div>
      {props.mdashAccountName && import.meta.env.VITE_ZENDESK_KEY && (
        <ZendeskBadge className="fixed print:hidden right-4 bottom-10 z-100" />
      )}
    </div>
  )
}

export default DefaultLayout
