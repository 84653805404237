import { FC, PropsWithChildren, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAlarmExclamation,
  faInfo,
  faLightsHoliday,
  faTriangleExclamation,
} from '@fortawesome/pro-duotone-svg-icons'
import Modal, { IModalProps } from 'src/components/02-molecules/Modal'
import Markdown from 'markdown-to-jsx'
import Button from 'src/components/01-atoms/Button'

import './styles.css'
import classNames from 'classnames'

export interface IMegaphoneModalProps extends IModalProps, PropsWithChildren {
  /**
   * String to be rendered as headline
   */
  title?: string | null

  /**
   * String to be rendered as markdown
   */
  messageBody?: string

  /**
   * String to be rendered as button
   */
  actionButtonText?: string | null

  /**
   * Variant of the modal
   */
  megaphoneType?: 'alert' | 'info' | 'warning' | 'friendly'
}

const MegaphoneModal: FC<IMegaphoneModalProps> = ({
  title = 'This is Very Important',
  messageBody,
  actionButtonText = 'Got it',
  megaphoneType = 'alert',
  children,
  ...modalProps
}) => {
  const [ isOpen, setIsOpen ] = useState( true )

  const megaphoneTypeMap = {
    alert: {
      topBarClasses: 'bg-red-500',
      iconClasses: 'bg-red-100 text-red-600',
      icon: faAlarmExclamation,
    },
    info: {
      topBarClasses: 'bg-gb-blue-700',
      iconClasses: 'bg-gb-blue-100 text-gb-blue-700',
      icon: faInfo,
    },
    warning: {
      topBarClasses: 'bg-yellow-500',
      iconClasses: 'bg-yellow-100 text-yellow-800',
      icon: faTriangleExclamation,
    },
    friendly: {
      topBarClasses: 'bg-green-700',
      iconClasses: 'bg-green-100 text-green-700',
      icon: faLightsHoliday,
    },
  }

  const { topBarClasses, iconClasses, icon } = megaphoneTypeMap[megaphoneType]

  return (
    <Modal
      {...modalProps}
      open={isOpen}
      handleClose={() => setIsOpen( false )}
      contentClassName="md:center w-10/12 sm:max-w-xl lg:max-w-2xl rounded p-0"
    >
      <div
        data-testid="megaphone-modal"
        data-type={megaphoneType}
        className="md:flex md:flex-col md:center text-sm w-full"
      >
        <div className={classNames( 'w-full flex flex-col center h-16 mb-12', topBarClasses )}>
          <div
            className={classNames(
              'aspect-square w-20 h-20 rounded-full flex center -mb-16',
              iconClasses
            )}
            data-testid="icon"
          >
            <FontAwesomeIcon icon={icon} size="3x" />
          </div>
        </div>
        <div className="text-center px-12">
          <p className="font-semibold text-3xl mb-6" data-testid="title">
            {title}
          </p>
          <div className="text-base markdown" data-testid="message-body">
            {messageBody && <Markdown>{messageBody}</Markdown>}
            {children}
          </div>
        </div>
        <Button
          data-testid="action-button"
          onClick={() => setIsOpen( false )}
          className="my-10 mx-auto"
          outline
        >
          {actionButtonText ?? 'Got it'}
        </Button>
      </div>
    </Modal>
  )
}

export default MegaphoneModal
