import { InputHTMLAttributes, forwardRef, Ref, useState } from 'react'
import { useDebounce } from 'rooks'
import mergeClassNames from 'src/utils/helpers/mergeClassNames'

export interface ITextInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  type?: 'email' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'url'
  labelText?: string
  ref?: Ref<HTMLInputElement>
  outline?: boolean
  showLabel?: boolean
  error?: string
  isInvalidExternally?: boolean
  helperText?: string
  wrapperClassName?: string

  /**
   * Whether or not space under the input box should be preserved to prevent too much layout shift.
   */
  reduceLayoutShift?: boolean
}

const TextInput = forwardRef<HTMLInputElement, ITextInputProps>(
  (
    {
      labelText,
      showLabel,
      className,
      wrapperClassName,
      outline = false,
      type = 'text',
      error,
      isInvalidExternally = false,
      helperText,
      reduceLayoutShift = false,
      ...inputProps
    },
    ref
  ) => {
    const [ isInvalid, setIsInvalid ] = useState( isInvalidExternally )
    const setInvalidDebounced = useDebounce( setIsInvalid, 500 )
    const pattern = {
      tel: /^(1?\d{10}(\s*(x|ext\.+|extension *)(\d+))?)$/,
      email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      number: /\d*/,
      text: /.*/,
      password: /.*/,
      search: /.*/,
      url: /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)$/,
    }[type]

    const { onChange, onBlur, disabled, ...unchangedInputProps } = inputProps

    const isRequired = inputProps.required && !inputProps.readOnly
    const properLabelText = `${labelText}${isRequired ? ' *' : ''}`

    return (
      <label htmlFor={inputProps.id} className={wrapperClassName}>
        <span
          className={mergeClassNames(
            {
              'sr-only': !showLabel,
            },
            'mb-1 inline-block text-gb-gray-900 font-weight-normal font-medium text-sm'
          )}
        >
          {properLabelText}
        </span>
        <input
          ref={ref}
          type={type}
          pattern={pattern.source ?? undefined}
          maxLength={255}
          placeholder={!showLabel ? properLabelText : inputProps.placeholder}
          aria-invalid={isInvalidExternally || isInvalid}
          onChange={( e ) => {
            setInvalidDebounced( !e.currentTarget.validity.valid )
            onChange?.( e )
          }}
          onBlur={( e ) => {
            setIsInvalid( !e.currentTarget.validity.valid )
            onBlur?.( e )
          }}
          disabled={disabled || inputProps.readOnly}
          required={isRequired}
          className={mergeClassNames(
            'text-input w-full p-2 rounded text-sm focus:outline-gb-blue-600 bg-white placeholder:text-gb-gray-800 disabled:placeholder-shown:opacity-50 invalid:border-red-600',
            {
              'border-2 border-gb-gray-400': outline,
              'border-red-600': outline && isInvalidExternally,
              'disabled:bg-gb-gray-100': !inputProps.readOnly,
              'border-0 px-0': inputProps.readOnly,
            },
            className
          )}
          {...unchangedInputProps}
        />
        {( reduceLayoutShift || helperText || error ) && (
          <div className="mt-1 text-xs">
            {helperText && <span className="block text-gb-gray-800">{helperText}</span>}
            {( isInvalidExternally || isInvalid ) && error && (
              <span className="text-red-600 error-message">{error}</span>
            )}
            {reduceLayoutShift && <>&nbsp;</>}
          </div>
        )}
      </label>
    )
  }
)

export default TextInput
