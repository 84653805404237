import { FC, useState, useEffect, useMemo, lazy } from 'react'
import { Route, Routes, useNavigate, useLocation, useMatch } from 'react-router-dom'
import { useQuery } from 'urql'
import { Elements as StripeElements } from '@stripe/react-stripe-js'

import { IMegaphoneType } from 'src/components/01-atoms/Megaphone'
import DefaultLayout from 'src/components/04-templates/DefaultLayout'
import FullViewportLayout from 'src/components/04-templates/FullViewportLayout'
import { IMegaphoneModalProps } from 'src/components/03-organisms/MegaphoneModal'
import AppLoading from './components/05-pages/AppLoading'

import AdminOnly from 'src/pages/elements/AdminOnly'
import RunOnLoadWrapper from 'src/pages/elements/RunOnLoadWrapper'

import useLogging from 'src/utils/hooks/useLogging'
import { stripePromise, stripeOptions } from 'src/utils/helpers/stripeInit'
import UserContext, { IIdentity } from 'src/contexts/UserContext'
import { IChildLink } from 'src/utils/types/IChildLink'

import getUserIdentity from 'src/graphql/queries/getUserIdentity.graphql'
import {
  IGetUserIdentityQuery,
  IGetUserIdentityQueryVariables,
} from 'src/graphql/queries/getUserIdentity.types'
import getMDashAccount from 'src/graphql/queries/getMdashAccount.graphql'
import {
  IGetMdashAccountQuery,
  IGetMdashAccountQueryVariables,
} from 'src/graphql/queries/getMdashAccount.types'
import getMetabaseReportsByCategory from 'src/graphql/queries/getMetabaseReportsByCategory.graphql'
import {
  IGetMetabaseReportsByCategoryQuery,
  IGetMetabaseReportsByCategoryQueryVariables,
} from 'src/graphql/queries/getMetabaseReportsByCategory.types'

import getMegaphone from 'src/graphql/queries/getMegaphone.graphql'
import {
  IGetMegaphoneQuery,
  IGetMegaphoneQueryVariables,
} from 'src/graphql/queries/getMegaphone.types'
import getMegaphoneModal from 'src/graphql/queries/getMegaphoneModal.graphql'
import {
  IGetMegaphoneModalQuery,
  IGetMegaphoneModalQueryVariables,
} from './graphql/queries/getMegaphoneModal.types'

import 'src/styles/global.css'

const Login = lazy(() => import( 'src/pages/Login' ))
const Manifest = lazy(() => import( 'src/pages/Manifest' ))
const OrderDetail = lazy(() => import( 'src/pages/OrderDetail' ))
const PackageDetail = lazy(() => import( 'src/pages/PackageDetail' ))
const ProductSummary = lazy(() => import( 'src/pages/ProductSummary' ))
const Settings = lazy(() => import( 'src/pages/Settings' ))
const FulfillmentSheet = lazy(() => import( 'src/pages/FulfillmentSheet' ))
const SlipNLabels = lazy(() => import( 'src/pages/SlipNLabels' ))
const Discard = lazy(() => import( 'src/pages/Discard' ))
const DiscardAndReprint = lazy(() => import( 'src/pages/DiscardAndReprint' ))
const ConfirmReprint = lazy(() => import( 'src/pages/ConfirmReprint' ))
const PickList = lazy(() => import( 'src/pages/PickList' ))
const WhatsNew = lazy(() => import( 'src/pages/WhatsNew' ))
const Report = lazy(() => import( 'src/pages/Report' ))
const Adoption = lazy(() => import( 'src/pages/AdminPages/Adoption' ))
const Checkout = lazy(() => import( 'src/pages/Checkout/' ))
const NewCheckout = lazy(() => import( 'src/pages/Checkout/New/' ))
const LogoutModal = lazy(() => import( 'src/pages/elements/LogoutModal' ))
const PantryItemLimit = lazy(() => import( 'src/pages/PantryItemLimit' ))
const ProtectedByPermission = lazy(() => import( 'src/pages/elements/ProtectedByPermission' ))
const AutoAssignment = lazy(() => import( 'src/pages/AutoAssignment' ))
const ProductTags = lazy(() => import( 'src/pages/ProductTaxonomies' ))
const GiftCardRedemptions = lazy(() => import( 'src/pages/StatementPages/GiftCardRedemptions' ))
const Statements = lazy(() => import( 'src/pages/Statements' ))
const Statement = lazy(() => import( 'src/pages/StatementPages/Statement' ))
const SalesReport = lazy(() => import( 'src/pages/StatementPages/SalesReport' ))
const MissedShipments = lazy(() => import( 'src/pages/StatementPages/MissedShipments' ))
const Reshipments = lazy(() => import( 'src/pages/StatementPages/Reshipments' ))
const ReshipmentIssues = lazy(() => import( 'src/pages/IssuesPages/ReshipmentIssues' ))
const Refunds = lazy(() => import( 'src/pages/StatementPages/Refunds' ))
const Cancellations = lazy(() => import( 'src/pages/StatementPages/Cancellations' ))
const Promotions = lazy(() => import( 'src/pages/StatementPages/Promotions' ))
const Others = lazy(() => import( 'src/pages/StatementPages/Others' ))
const StatementsLanding = lazy(() => import( 'src/pages/StatementsLanding' ))
const PrintMap = lazy(() => import( 'src/pages/AdminPages/PrintMap' ))
const SigmaExample = lazy(() => import( 'src/pages/AdminPages/SigmaExample' ))
const Issues = lazy(() => import( 'src/pages/Issues' ))
const RefundsIssues = lazy(() => import( 'src/pages/IssuesPages/RefundsIssues' ))
const MissedShipmentsIssues = lazy(() => import( 'src/pages/IssuesPages/MissedShipmentsIssues' ))
const IssuesCalendar = lazy(() => import( 'src/pages/AdminPages/IssuesCalendar' ))
const PurchaserProfile = lazy(() => import( 'src/pages/PurchaserProfile' ))
const PurchasersReport = lazy(() => import( 'src/pages/Reports/Purchasers' ))

const App: FC = () => {
  const log = useLogging()
  const location = useLocation()
  const navigate = useNavigate()
  const mdashAccountMatches = useMatch( ':mdashAccountId/*' )

  const [ showLogoutModal, setShowLogoutModal ] = useState( false )

  const mdashAccountIdFromURL: string | null | undefined =
    parseInt( mdashAccountMatches?.params.mdashAccountId ?? '', 10 ) > 0
      ? mdashAccountMatches?.params.mdashAccountId
      : null

  const [ userInfo, setUserInfo ] = useState<IIdentity>({
    userId: '',
    userName: '',
    mdashAccountId: mdashAccountIdFromURL ?? '',
    mdashAccountName: '',
    isAdmin: false,
    reportLinks: {},
  })

  const [ userIdentityResponse ] = useQuery<IGetUserIdentityQuery, IGetUserIdentityQueryVariables>({
    query: getUserIdentity,
    requestPolicy: 'cache-and-network',
  })
  const { fetching, data, error } = userIdentityResponse

  const [{ data: megaphoneData }] = useQuery<IGetMegaphoneQuery, IGetMegaphoneQueryVariables>({
    query: getMegaphone,
    pause: Number( mdashAccountIdFromURL || 0 ) < 1,
  })

  const [{ data: accountData }, refetchMDashAccount ] = useQuery<
    IGetMdashAccountQuery,
    IGetMdashAccountQueryVariables
  >({
    query: getMDashAccount,
    variables: {
      id: mdashAccountIdFromURL,
    },
    pause: Number( mdashAccountIdFromURL || 0 ) < 1,
    requestPolicy: 'cache-and-network',
  })

  const [{ data: megaphoneModalData }] = useQuery<
    IGetMegaphoneModalQuery,
    IGetMegaphoneModalQueryVariables
  >({
    query: getMegaphoneModal,
    variables: {
      mdashAccountId: mdashAccountIdFromURL,
    },
    pause: Number( mdashAccountIdFromURL || 0 ) < 1,
  })

  const [{ data: reportsData }] = useQuery<
    IGetMetabaseReportsByCategoryQuery,
    IGetMetabaseReportsByCategoryQueryVariables
  >({
    query: getMetabaseReportsByCategory,
    variables: {
      mdashAccountId: mdashAccountIdFromURL ?? '',
    },
    pause: Number( mdashAccountIdFromURL || 0 ) < 1 && !userInfo.isAdmin,
  })

  const reportsAsLinks = ( category: string ): IChildLink[] | undefined => {
    const report = reportsData?.metabaseReportsByCategory?.filter(( r ) => r.category === category )[0]

    if ( report && report.reports ) {
      return report.reports.map(( report ) => ({
        to: report.url
          ? `${userInfo.mdashAccountId}/${report.url}`
          : `${userInfo.mdashAccountId}/report/${report?.id ?? ''}`,
        text: report?.name ?? '',
      }))
    }

    return undefined
  }

  const userContextValue = useMemo(
    () => ({
      identity: userInfo,
      setIdentity: setUserInfo,
    }),
    [ userInfo ]
  )

  useEffect(() => {
    if ( fetching || error || !data || !data.identity ) return

    const { adminUser, merchantUser } = data.identity
    const isAdmin = !!adminUser?.id
    const userId = isAdmin ? adminUser?.id : merchantUser?.id
    const userName = isAdmin ? adminUser?.name : merchantUser?.name
    const mdashAccountId =
      isAdmin && mdashAccountIdFromURL ? mdashAccountIdFromURL : merchantUser?.mdashAccount.id
    const mdashAccountName = merchantUser?.mdashAccount?.name
    const merchantUserPermissions = merchantUser?.permissions
    const displayPackageBarcode = accountData?.mdashAccount?.displayPackageBarcode
    const displaySku = accountData?.mdashAccount?.displaySku
    const useDryIcePlaceholder = accountData?.mdashAccount?.dryIceUsePlaceholder
    const isMultiPickUpLocation = merchantUser?.mdashAccount.isMultiPickUpLocation
    const isMultiShippingFacility = merchantUser?.mdashAccount.isMultiShippingFacility
    const isSelfShipper =
      merchantUserPermissions?.canDownloadTracking ||
      merchantUserPermissions?.canUploadTracking ||
      false
    const isPickUpEnabled = merchantUser?.mdashAccount.isPickUpEnabled
    const defaultMerchantId = accountData?.mdashAccount?.defaultMerchantId
    const mdashAccountPermissions = accountData?.mdashAccount?.permissions
    const accountQueryOverride =
      Number( accountData?.mdashAccount?.id || 0 ) > 0
        ? {
            mdashAccountName: accountData?.mdashAccount?.name,
            mdashAccountPermissions,
            isMultiPickUpLocation: accountData?.mdashAccount?.isMultiPickUpLocation,
            isMultiShippingFacility: accountData?.mdashAccount?.isMultiShippingFacility,
            shippingFacilities: accountData?.mdashAccount?.shippingFacilities,
          }
        : {}

    const statements = reportsAsLinks( 'statements' )
    const performanceLinks = reportsAsLinks( 'performance' )
    const productsLinks = reportsAsLinks( 'products' )
    const purchasersLinks = mdashAccountPermissions?.canQueryPurchaserInfo
      ? [
          {
            to: `${mdashAccountId}/report/purchasers`,
            text: 'Purchasers',
          },
        ]
      : []

    const reportLinks = {
      performanceLinks: performanceLinks?.length ? performanceLinks : undefined,
      productsLinks: productsLinks?.length ? productsLinks : undefined,
      purchasersLinks: purchasersLinks?.length ? purchasersLinks : undefined,
    }

    setUserInfo(( prev ) => ({
      ...prev,
      userId,
      userName,
      merchantUserPermissions,
      mdashAccountId,
      mdashAccountName,
      displayPackageBarcode,
      displaySku,
      isAdmin,
      isSelfShipper,
      isMultiPickUpLocation,
      isMultiShippingFacility,
      isPickUpEnabled,
      useDryIcePlaceholder,
      defaultMerchantId: defaultMerchantId ?? undefined,
      showStatements: reportsData?.metabaseReportsByCategory
        ? typeof statements !== 'undefined'
        : undefined,
      reportLinks,
      ...accountQueryOverride,
    }))

    if ( location.pathname !== '/login' && !userId ) {
      navigate( '/login', { replace: true })
    }
  }, [ fetching, data, error, accountData, reportsData ])

  useEffect(() => {
    window.analytics.page( location.pathname )
  }, [ location.pathname ])

  if ( !fetching && ( !data || error )) {
    log(
      'Fetching user identity is complete, but there is no data. The user is probably seeing the loading screen.',
      { userIdentityResponse, userInfo, error, data, fetching },
      'error'
    )
  }

  if ( fetching || !data ) return <AppLoading />

  /**
   * Keep the routes here in sync with the link Urls in useAppParams.ts as much as possible.
   */
  return (
    <UserContext.Provider value={userContextValue}>
      <Routes>
        <Route element={<FullViewportLayout />}>
          <Route index element={<Login />} />
          <Route path="login">
            <Route index element={<Login />} />
          </Route>
        </Route>

        <Route
          element={
            <DefaultLayout
              {...userInfo}
              megaphone={{
                markdown: megaphoneData?.megaphone?.messageBody,
                type: megaphoneData?.megaphone?.megaphoneType as IMegaphoneType,
              }}
              modal={megaphoneModalData?.megaphoneModal as IMegaphoneModalProps}
              logoutButtonProps={{
                logoutButtonAction: () => setShowLogoutModal( true ),
                disabled: showLogoutModal,
              }}
              linkPrefix={userInfo.mdashAccountId}
            />
          }
        >
          <Route path="/admin" element={<AdminOnly />}>
            <Route index element={<IssuesCalendar />} />
            <Route path="adoption" element={<Adoption />} />
            <Route path="printmap" element={<PrintMap />} />
            <Route path="issues">
              <Route index element={<IssuesCalendar />} />
              <Route path=":toShipOn" element={<IssuesCalendar />} />
            </Route>

            {/* Setting up this temporary example for the Data team */}
            <Route path="sigma-example" element={<SigmaExample />} />
          </Route>
          <Route path="/:mdashAccountId">
            <Route index element={<Manifest />} />
            <Route path="manifest" element={<Manifest />}>
              <Route path=":toShipOn" element={<Manifest />} />
            </Route>
            <Route path="whats_new" element={<WhatsNew />} />

            <Route path="labels" element={<RunOnLoadWrapper fn={refetchMDashAccount} />}>
              <Route path=":toShipOn">
                <Route path="discard" element={<Discard />} />
                <Route path="reprint" element={<DiscardAndReprint />} />
                <Route path="confirm_reprint" element={<ConfirmReprint />} />
                <Route path="print" element={<SlipNLabels />} />
              </Route>
            </Route>

            <Route path="issues/" element={<Issues />}>
              <Route index element={<MissedShipmentsIssues />} />
              <Route path="reshipments" element={<ReshipmentIssues />} />
              <Route path="missed_shipments" element={<MissedShipmentsIssues />} />
              <Route path="refunds" element={<RefundsIssues />} />
            </Route>

            <Route path="fulfillment_sheets/:toShipOn" element={<FulfillmentSheet />} />

            <Route path="pick_list" element={<PickList />}>
              <Route path=":toShipOn" element={<PickList />} />
            </Route>

            <Route
              element={<ProtectedByPermission accountPermissions={[ 'canQueryPurchaserInfo' ]} />}
            >
              <Route path="order/:orderId" element={<OrderDetail />} />
              <Route path="purchaser/:purchaserId" element={<PurchaserProfile />} />
              <Route path="report/purchasers" element={<PurchasersReport />} />
            </Route>
            <Route path="package/:packageId" element={<PackageDetail />} />
            <Route path="subproducts/:toShipOn?" element={<PantryItemLimit />} />
            <Route path="report/:reportId" element={<Report />} />
            <Route path="auto_assignments" element={<AutoAssignment />} />
            <Route path="product_tags" element={<ProductTags />} />

            <Route
              path="checkout"
              element={<ProtectedByPermission userPermissions={[ 'canUseGuac' ]} />}
            >
              <Route
                path=":orderId"
                element={
                  <StripeElements stripe={stripePromise} options={stripeOptions}>
                    <Checkout />
                  </StripeElements>
                }
              />
              <Route path="new" element={<NewCheckout />}>
                <Route path=":purchaserAccountId" />
              </Route>
            </Route>

            <Route path="statements" element={<StatementsLanding />}>
              <Route index element={<Statements />} />
              <Route path=":statementId" element={<Statement />} />
              <Route path=":statementId/sales" element={<SalesReport />} />
              <Route path=":statementId/missed_shipments" element={<MissedShipments />} />
              <Route path=":statementId/reshipments" element={<Reshipments />} />
              <Route path=":statementId/refunds" element={<Refunds />} />
              <Route path=":statementId/cancellations" element={<Cancellations />} />
              <Route path=":statementId/promotions" element={<Promotions />} />
              <Route path=":statementId/gift_card_redemptions" element={<GiftCardRedemptions />} />
              <Route path=":statementId/others" element={<Others />} />
            </Route>

            <Route path="summary" element={<ProductSummary />}>
              <Route path=":toShipOn" element={<ProductSummary />} />
            </Route>
            <Route path="settings" element={<Settings />} />
          </Route>
        </Route>
      </Routes>
      <LogoutModal open={showLogoutModal} handleClose={() => setShowLogoutModal( false )} />
    </UserContext.Provider>
  )
}

export default App
