import supportedBrowsers from 'supportedBrowsers.js'

/**
 * Checks to see if the application supports a given browser.
 * @param {string} userAgent A provided user agent string. Defaults to the current window.navigator.userAgent.
 * @returns {boolean} Whether or not the browser is supported.
 */
const isBrowserSupported = ( userAgent: string = window.navigator.userAgent ) =>
  supportedBrowsers.test( userAgent )

export default isBrowserSupported
