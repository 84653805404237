import { FC } from 'react'

import Grid from 'src/components/01-atoms/Grid'
import InlineLink from 'src/components/01-atoms/InlineLink'
import Logo, { LogoType } from 'src/components/01-atoms/Logo'
import SpinLoader from 'src/components/01-atoms/SpinLoader'

const AppLoading: FC = () => (
  <main className="w-[100vw] h-[100vh]" data-testid="app-loading">
    <Grid className="h-full px-16">
      <section className="h-full w-full col-span-12 bg-gb-gray-50 flex flex-col gap-8 items-center justify-center">
        <Logo type={LogoType.MERCHANT} className="max-w-[11rem]" />
        <p className="text-center max-w-125">Please hold! The Merchant Dashboard is working to connect to Goldbelly&apos;s servers. Thank you so much for your patience.</p>
        <p className="text-sm text-center">
          If you see this message for more than 2 &ndash; 5 minutes, please try refreshing the page.<br />
          If you continue to see this after multiple tries, please contact <InlineLink to="mail:partners@goldbelly.com">partners@goldbelly.com</InlineLink>.
        </p>
        <SpinLoader className="text-gb-blue-600" size="3x" />
      </section>
    </Grid>
  </main>
)

export default AppLoading
