export const headers = import.meta.env.VITE_MONOLITH_STAGING_PASSWORD
  ? {
      Authorization:
        'Basic ' +
        Buffer.from(
          import.meta.env.VITE_MONOLITH_STAGING_USERNAME +
            ':' +
            import.meta.env.VITE_MONOLITH_STAGING_PASSWORD
        ).toString( 'base64' ),
    }
  : {
      'x-vercel-bypass': import.meta.env.VITE_CLOUDFLARE_BYPASS_TOKEN as string,
    }
