/**
 * Reference date-fns official documentation for more info: https://date-fns.org/docs/
 */

import {
  addBusinessDays,
  addDays,
  addMonths,
  format,
  formatISO,
  getDay,
  isToday,
  isTomorrow,
  parseISO,
  setDate,
  startOfDay,
  subBusinessDays,
  subDays,
  subMonths,
  nextThursday,
  addWeeks,
} from 'date-fns'
import { toZonedTime } from 'date-fns-tz'
import range from 'lodash/range'

// ------- Types & Enums -------

export enum RelativeDay {
  TODAY = 'Today',
  TOMORROW = 'Tomorrow',
}

// ------- Conversions -------

/**
 * Convert a string to a date object
 * @param {string} date
 * @returns {Date} The date object.
 */
export const stringAsDate = ( date: string ): Date => {
  if ( date.match( /^[\d]{4}-[\d]{2}-[\d]{2}$/ )) return parseISO( date )
  return toZonedTime( new Date( date ), Intl.DateTimeFormat().resolvedOptions().timeZone )
}

/**
 * @param {Date} date The date to be formatted.
 * @returns {string} The date in ISO format as a string to be used across the app.
 * (Example: `2022-02-22`)
 */
export const dateForManifest = ( date: Date ): string => formatISO( date, { representation: 'date' })

// ------- Formatting -------

/**
 * @param {Date} date The date to be formatted.
 * @returns {string} A string in the format of "day. date". (Example: `Jul. 4`)
 */
export const shortMonthAndDate = ( date: Date ): string => format( date, 'MMM. d' )

/**
 * @param {Date} date The date to be formatted.
 * @returns {string} The full name of the day of the week as a string.
 */
export const dayOfTheWeek = ( date: Date ): string => format( date, 'eeee' )

/**
 * @param {Date} date The date to be formatted.
 * @returns {string} The full name of the day of the week as a string.
 */
export const shortDayOfTheWeek = ( date: Date ): string => format( date, 'eee' )

/**
 * @param {Date} date The date to be formatted.
 * @returns {string} The short date string. (Example: `2/2/22`)
 */
export const shortDate = ( date: Date ): string => format( date, 'M/d/yy' )

/**
 * @param {Date} date The date to be formatted.
 * @returns {string} The short time string with meridiem. (Example: `2:22pm`)
 */
export const shortTimeWithMeridiem = ( date: Date ): string => format( date, 'h:mmaaa' )

/**
 * @param {Date} date The date to be formatted.
 * @returns {string} The long format date string with the day.
 */
export const longFormatDayAndDate = ( date: Date ): string => format( date, 'eee, MMM d, yyyy' )

/**
 * @param {Date} date The date to be formatted.
 * @returns {string} The long format date string.
 */
export const longFormatDate = ( date: Date ): string => format( date, 'MMM d, yyyy' )

/**
 *
 * @param {Date} date The date to be formatted.
 * @returns {string} The day number with the number suffix ("st", "nd", "rd", "th", etc.)
 */
export const dayWithNumberSuffix = ( date: Date ): string => format( date, 'do' )

/**
 * @param {Date} date The date to be formatted.
 * @param {CallableFunction} formatMethod The method by which the date should be formatted.
 * @returns {string} If today, return `Today`. Else, return the day of the week as a string using
 * the given format method.
 */
export const todayOrElse = ( date: Date, formatMethod: CallableFunction = dayOfTheWeek ): string =>
  isToday( date ) ? 'Today' : formatMethod( date )

/**
 * @param {Date} date The date to be formatted.
 * @param {CallableFunction} formatMethod The method by which the date should be formatted.
 * @returns {string} If today, return `Today`. If tomorrow, return `Tomorrow`. Else, return the day
 * of the week as a string using the given format method.
 */
export const todayTomorrowOrElse = (
  date: Date,
  formatMethod: CallableFunction = dayOfTheWeek
): RelativeDay => {
  if ( isToday( date )) return RelativeDay.TODAY
  if ( isTomorrow( date )) return RelativeDay.TOMORROW
  return formatMethod( date )
}

/**
 * @param {Date} date The date to be formatted.
 * @returns {string} The full month name.
 */
export const fullMonthName = ( date: Date ): string => format( date, 'LLLL' )

// ------- Constants -------

/**
 * The date of order 1, even though it looks like it was a test. ;)
 * We should ultimately get the oldest order date from each merchant to make their calendars
 * relevant to them, even though it's very unlikely they'd go that far back.
 */
export const oldestPossible = new Date( 2011, 12, 18 )

// ------- Date Math -------

/**
 * @returns {Date} Today at 00:00:00.
 */
export const getToday = () => startOfDay( new Date())

/**
 * @returns {Date} Previous day at 00:00:00 relative to the given date.
 */
export const getPrevDay = ( date: Date ) => startOfDay( subBusinessDays( date, 1 ))

/**
 * @returns {Date} Next day at 00:00:00 relative to the given date.
 */
export const getNextDay = ( date: Date ) => startOfDay( addBusinessDays( date, 1 ))

/**
 * @param {Date} date The origin date.
 * @returns {Date} The date 5 business days prior.
 */
export const getPrevWeek = ( date: Date ): Date => subDays( date, 7 )

/**
 *
 * @param {Date} date The origin date.
 * @returns {Date} The date 5 business pays post.
 */
export const getNextWeek = ( date: Date ): Date => addDays( date, 7 )

/**
 * Get the first weekday of a given month.
 * @param {Date} date A date containing the month and year to get the first weekday.
 * @returns {Date} The first weekday of the month.
 */
export const getFirstWeekdayInMonth = ( date: Date ): Date => {
  const firstDayInMonth = setDate( date, 1 )
  const dateDay = getDay( firstDayInMonth )

  switch ( dateDay ) {
    case 0:
      return addDays( firstDayInMonth, 1 ) // Sunday -> Monday
    case 6:
      return addDays( firstDayInMonth, 2 ) // Saturday -> Monday
    default:
      return firstDayInMonth
  }
}

/**
 * @param {Date} date The origin date.
 * @returns {Date} The date 1 month prior.
 */
export const getPrevMonth = ( date: Date ): Date => getFirstWeekdayInMonth( subMonths( date, 1 ))

/**
 * @param {Date} date The origin date.
 * @returns {Date} The date 1 month post.
 */
export const getNextMonth = ( date: Date ): Date => getFirstWeekdayInMonth( addMonths( date, 1 ))

/**
 * Get a list of the months of the year.
 * @returns {string[]} All of the months of the year.
 */
export const getMonthList = (): string[] =>
  range( 0, 12 ).map(( v ) => fullMonthName( new Date( 0, v, 1 )))

/**
 *
 * @param startYear The year to start with.
 * @param endYear The year to end with.
 * @returns {array} The generated list of years, including both start and end years.
 */
export const getYearList = ( startYear: number, endYear: number ): number[] => {
  const step = startYear > endYear ? -1 : 1
  return [ ...range( startYear, endYear, step ), endYear ]
}

// ------- Holidays + Important Dates -------

/**
 * Get the date object for Thanksgiving in the US.
 * @param year The year for which to retrieve Thanksgiving Day.
 * @returns The date object for 12:00 AM on Thanksgiving Day.
 */
export const getUSThanksgiving = ( year: number = new Date().getUTCFullYear()): Date => {
  const monthStart = new Date( `November 1, ${year} 00:00:00` )
  return addWeeks( monthStart.getDay() === 4 ? monthStart : nextThursday( monthStart ), 3 )
}
