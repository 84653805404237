import { Search } from 'react-router-dom'
import { IFacilityType, IManifestFilterCategory } from 'src/graphql/types'

export interface IFacilityInput {
  facilityId: '0' | string
  facilityType: IFacilityType | IManifestFilterCategory
}

const makeFacilityParams = ( facilityInput: IFacilityInput ): Search => {
  const searchParams = new URLSearchParams()
  const { facilityId, facilityType } = facilityInput

  if ( !facilityType || facilityId.length === 0 ) return ''

  searchParams.set( 'facility_id', Number( facilityId ) > 0 ? facilityId : '0' )
  searchParams.set( 'facility_type', facilityType )
  return searchParams.toString()
}

export default makeFacilityParams
