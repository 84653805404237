import { createContext, Dispatch, SetStateAction } from 'react'
import {
  IMDashAccountPermissionSet,
  IMerchantUserPermissionSet,
  IShippingFacility,
} from 'src/graphql/types'
import { IChildLink } from 'src/utils/types/IChildLink'

export interface IReportLinks {
  performanceLinks?: IChildLink[]
  productsLinks?: IChildLink[]
  purchasersLinks?: IChildLink[]
}
export interface IIdentity {
  userId?: string
  userName?: string
  mdashAccountId?: string
  mdashAccountName?: string
  merchantUserPermissions?: IMerchantUserPermissionSet
  displayPackageBarcode?: boolean
  displaySku?: boolean
  useDryIcePlaceholder?: boolean
  isAdmin?: boolean
  isSelfShipper?: boolean
  isMultiPickUpLocation?: boolean
  isMultiShippingFacility?: boolean
  isPickUpEnabled?: boolean
  shippingFacilities?: IShippingFacility[]
  mdashAccountPermissions?: IMDashAccountPermissionSet
  defaultMerchantId?: string
  showStatements?: boolean
  reportLinks?: IReportLinks
}
export interface UserContextProps {
  identity: IIdentity
  setIdentity?: Dispatch<SetStateAction<IIdentity>>
}

const UserContext = createContext<UserContextProps>({
  identity: {
    userId: '',
    userName: '',
    mdashAccountId: '',
    mdashAccountName: '',
    mdashAccountPermissions: {},
    displayPackageBarcode: false,
    displaySku: false,
    useDryIcePlaceholder: false,
    isAdmin: false,
    isSelfShipper: false,
    isMultiPickUpLocation: false,
    isMultiShippingFacility: false,
    isPickUpEnabled: false,
    shippingFacilities: [],
    showStatements: false,
    reportLinks: {},
  },
  setIdentity: () => {},
})

export default UserContext
