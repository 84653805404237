import {
  CombinedError,
  createClient,
  cacheExchange,
  fetchExchange,
  Operation,
  Exchange,
} from 'urql'
import { retryExchange, RetryExchangeOptions } from '@urql/exchange-retry'
import { OperationDefinitionNode } from 'graphql'

import { headers } from 'src/apiHeaders'

const retryOptions = {
  initialDelayMs: 1000,
  maxDelayMs: 15000,
  randomDelay: true,
  maxNumberAttempts: 10,
  retryIf: ( err: CombinedError, operation: Operation ): boolean => {
    const op = operation.query.definitions[0] as OperationDefinitionNode
    return !!( err && err.networkError && op.name?.value === 'getUserIdentity' )
  },
} as RetryExchangeOptions

export default createClient({
  url: import.meta.env.VITE_GRAPHQL_ENDPOINT || '//www.goldbelly.com/mdashx/graphql',
  fetchOptions: {
    headers: headers as unknown as Headers,
    credentials: 'include',
  },
  exchanges: [ cacheExchange, retryExchange( retryOptions ) as Exchange, fetchExchange ],
})
