import { FC } from 'react'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons'
import mergeClassNames from 'src/utils/helpers/mergeClassNames'

interface ISpinLoader extends Omit<FontAwesomeIconProps, 'icon'> {
  /**
   * Whether the spinner should be displayed or not.
   */
  loading?: boolean
}

const SpinLoader: FC<ISpinLoader> = ({ loading = true, className, ...props }) =>
  loading ? (
    <span data-testid="spin-loader">
      <FontAwesomeIcon
        icon={faSpinnerThird}
        {...props}
        className={mergeClassNames( 'color-inherit animate-spin motion-reduce:hidden', className )}
      />
      <span className="motion-safe:hidden">...</span>
      <span className="sr-only">Loading...</span>
    </span>
  ) : null

export default SpinLoader
