import isBrowserSupported from 'src/utils/helpers/isBrowserSupported'
import Megaphone from 'src/components/01-atoms/Megaphone'

const BrowserWarningMegaphone = () =>
  !isBrowserSupported() ? (
    <Megaphone
      data-testid="outdated-browser-warning"
      type="warning"
      markdown="**Your browser is no longer supported.** You may encounter some issues while using this application. Please update your browser to ensure the best experience."
    />
  ) : null

export default BrowserWarningMegaphone
