query getMdashAccount($id: ID) {
  mdashAccount(id: $id) {
    id
    name
    smsNotificationEnabled
    isMultiPickUpLocation
    isMultiShippingFacility
    defaultMerchantId
    displayPackageBarcode
    displaySku
    dryIceUsePlaceholder
    merchantNotificationContacts {
      firstName
      lastName
      phoneNumber
      id
      role
    }
    shippers {
      fulfillmentEmail
      id
      name
      receivesRealtimeNotifications
    }
    shippingFacilities {
      id
      name
      deletedAt
    }
    permissions {
      canQueryPurchaserInfo
      canPayInStore
      canPrintThermalShippingLabels
    }
  }
}
